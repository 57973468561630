/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import Content from 'components/Content'
import styled from 'styled-components'

const StyledContent = styled(Content)`
  & a {
    text-decoration: underline;
  }
`

const VacancyTemplate = ({ data: { page }, pageContext }) => (
  <Layout>
    <SEO seo={page.seo} />
    <div>
      <CustomBreadCrumb
        data={page}
        className="py-2"
        pageContext={pageContext}
      />
      <div className="container">
        <div className="row py-5 justify-content-center">
          <div className="col-lg-8 pl-lg-3">
            <StyledContent content={page.recapVacature.description} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpVacature(databaseId: { eq: $databaseId }) {
      title
      recapVacature {
        excerpt
        description
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default VacancyTemplate
